$primary: #053748;
$secondary: #1b1d23;
$link: #0eb2e4;
$divider: hsl(220, 13%, 20%);
$bg-color: hsl(220, 13%, 40%);
$text-title: hsl(194, 88%, 30%);
$bg-footer: hsl(0, 0%, 90%);
$white: #fff;
$black: #1b1d23;


$js-yellow: #E5D14B;
$css-blue: #188FD2;
$html-red: #E76128;
$node-green: #89BC3C;
$php-purple: #787CB5;


$font: "Poppins", sans-serif;


$mobile: 320;
$tablet: 768;
$desktop: 1200;
$desktop-large: 1500;
$desktop-max: 1920;


$media-tablet: $tablet + px;
$media-desktop: $desktop + px;
$media-desktop-large: $desktop-large + px;
$media-desktop-max: $desktop-max + px;