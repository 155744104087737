@import 'src/styles/imports';
$current: $mobile;
.contact-wrapper {
  margin-top: vw(10, $current);
  display: flex;
  align-items: center;
  font-weight: 100;
  font-size: vw(13, $current);
  text-transform: none;
  color: $link;

  .icon {
    font-size: vw(20, $current);
  }

  .info {
    color: $white;
    margin-inline-start: vw(10, $current);
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .contact-wrapper {
    margin-top: vw(10, $current);
    font-size: vw(16, $current);

    .icon {
      font-size: vw(20, $current);
    }

    .info {
      margin-inline-start: vw(10, $current);
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .contact-wrapper {
    margin-top: vw(10, $current);
    font-size: vw(16, $current);

    .icon {
      font-size: vw(20, $current);
    }

    .info {
      margin-inline-start: vw(10, $current);
    }
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .contact-wrapper {
    margin-top: vw(10, $current);
    font-size: vw(16, $current);

    .icon {
      font-size: vw(20, $current);
    }

    .info {
      margin-inline-start: vw(10, $current);
    }
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .contact-wrapper {
    margin-top: vw(10, $current);
    font-size: vw(16, $current);

    .icon {
      font-size: vw(20, $current);
    }

    .info {
      margin-inline-start: vw(10, $current);
    }
  }
}