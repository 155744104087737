@import 'src/styles/imports';
$current: $mobile;
.language-wrapper {
  &.line-flex-box {
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: inline-flex;
    width: 100%;
  }

  .language-name {
    text-transform: none;
    font-size: vw(16, $current);
  }

  .line-flex {
    flex: 1;
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .language-wrapper {
    &.line-flex-box {

    }

    .language-name {
      font-size: vw(16, $current);
    }
  }
}
@media (min-width: $media-desktop) {
  $current: $desktop;
  .language-wrapper {
    &.line-flex-box {

    }

    .language-name {
      font-size: vw(16, $current);
    }
  }
}
@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .language-wrapper {
    &.line-flex-box {

    }

    .language-name {
      font-size: vw(16, $current);
    }
  }
}
@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .language-wrapper {
    &.line-flex-box {

    }

    .language-name {
      font-size: vw(16, $current);
    }
  }
}