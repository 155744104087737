@import 'src/styles/imports';
$current: $mobile;
.main-wrapper {
  font-family: $font;
  background-color: $bg-color;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .sections-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $white;
  }
}

ul {
  list-style: none;

  li{
    &::before {
      font-weight: normal;
      content: "#";
      display: inline-block;
      margin-left: vw(-20, $current);
      margin-right: vw(10, $current);
      color: $link;
    }
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .main-wrapper {
    .sections-wrapper {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 vw(15, $current) $black;
      overflow: hidden;
      height: 100vh;
    }
  }

  ul {
    li{
      &::before {
        margin-left: vw(-20, $current);
        margin-right: vw(10, $current);
      }
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .main-wrapper {
    .sections-wrapper {
      width: vw(1000, $current);
      box-shadow: 0 0 vw(15, $current) $black;
    }
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .main-wrapper {
    .sections-wrapper {
      width: vw(1000, $current);
      box-shadow: 0 0 vw(15, $current) $black;
    }
  }

  ul {
    li{
      &::before {
        margin-left: vw(-20, $current);
        margin-right: vw(10, $current);
      }
    }
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .main-wrapper {
    .sections-wrapper {
      width: vw(1250, $current);
      border-radius: vw(10, $current);
      box-shadow: 0 0 vw(15, $current) $black;
      height: vw(900, $current);
    }
  }

  ul {
    li{
      &::before {
        margin-left: vw(-20, $current);
        margin-right: vw(10, $current);
      }
    }
  }
}