@import 'src/styles/imports';
$current: $mobile;
.education-wrapper {
  &:not(:last-of-type) {
    padding-bottom: vw(10, $current);
    margin-bottom: vw(10, $current);
    border-bottom: 1px solid $black;
  }
  .school-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: vw(14, $current);
    font-weight: 300;

    .school {

    }

    .year {
      text-align: end;
      font-size: vw(12, $current);
      white-space: nowrap;
    }
  }

  .title {
    font-size: vw(16, $current);
    font-weight: 500;
  }

  .speciality {
    font-size: vw(12, $current);
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .education-wrapper {
    &:not(:last-of-type) {
      padding-bottom: vw(10, $current);
      margin-bottom: vw(10, $current);
    }
    .school-wrapper {
      font-size: vw(14, $current);

      .year {
        font-size: vw(12, $current);
      }
    }

    .title {
      font-size: vw(16, $current);
    }

    .speciality {
      font-size: vw(12, $current);
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .education-wrapper {
    &:not(:last-of-type) {
      padding-bottom: vw(10, $current);
      margin-bottom: vw(10, $current);
    }
    .school-wrapper {
      font-size: vw(14, $current);

      .year {
        font-size: vw(12, $current);
      }
    }

    .title {
      font-size: vw(16, $current);
    }

    .speciality {
      font-size: vw(12, $current);
    }
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .education-wrapper {
    &:not(:last-of-type) {
      padding-bottom: vw(10, $current);
      margin-bottom: vw(10, $current);
    }
    .school-wrapper {
      font-size: vw(14, $current);

      .year {
        font-size: vw(12, $current);
      }
    }

    .title {
      font-size: vw(16, $current);
    }

    .speciality {
      font-size: vw(12, $current);
    }
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .education-wrapper {
    &:not(:last-of-type) {
      padding-bottom: vw(10, $current);
      margin-bottom: vw(10, $current);
    }
    .school-wrapper {
      font-size: vw(14, $current);

      .year {
        font-size: vw(12, $current);
      }
    }

    .title {
      font-size: vw(16, $current);
    }

    .speciality {
      font-size: vw(12, $current);
    }
  }
}