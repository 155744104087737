@import 'src/styles/imports';
$current: $mobile;
.left-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: $white;
  background-color: $secondary;
  padding-bottom: vw(20, $current);

  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: vw(30, $current) 0 vw(15, $current);

    .photo-wrapper {
      width: vw(150, $current);
      height: vw(150, $current);
      border-radius: 50%;
      overflow: hidden;

      .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      font-size: vw(20, $current);
      font-weight: 500;
      margin-top: vw(10, $current);
      text-transform: uppercase;
      display: block;
    }

    .title {
      font-size: vw(18, $current);
      font-weight: 200;
      margin-top: vw(10, $current);
      text-transform: uppercase;
      display: block;
      text-align: center;

      &.smaller {
        margin-top: unset;
        font-size: vw(12, $current);
      }
    }
  }


  .sections-wrapper {
    width: 85%;
    margin-inline: auto;
    .line {
      border: 0.1px $divider solid;
      margin-top: vw(10, $current);
    }

    .left-side-section {
      width: 100%;
      margin-top: vw(10, $current);
      display: block;
      text-align: left;
      text-transform: uppercase;
      font-size: vw(20, $current);
      font-weight: 400;
      width: 100%;

      > span {
        font-size: vw(16, $current);
        font-weight: 300;
      }

      a {
        text-decoration: none;
        color: $white;
        font-weight: 200;
      }
    }

    .badges-wrapper {
      margin-top: vw(20, $current);
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      justify-content: space-around;
      flex-direction: row;
      display: flex;

      .badge {
        width: vw(75, $current);
        height: vw(75, $current);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .left-section-wrapper {
    height: 100vh;
    width: vw(275, $current);
    padding-bottom: unset;

    .header-wrapper {
      margin-top: auto;
      padding: 0 0 vw(15, $current);

      .photo-wrapper {
        width: vw(150, $current);
        height: vw(150, $current);
      }

      .name {
        font-size: vw(20, $current);
        margin-top: vw(10, $current);
      }

      .title {
        font-size: vw(18, $current);
        margin-top: vw(10, $current);

        &.smaller {
          font-size: vw(12, $current);
        }
      }
    }


    .sections-wrapper {
      margin-bottom: auto;
      .line {
        margin-top: vw(10, $current);
      }

      .left-side-section {
        margin-top: vw(10, $current);
        font-size: vw(20, $current);

        > span {
          font-size: vw(16, $current);
        }
      }

      .badges-wrapper {
        margin-top: vw(20, $current);

        .badge {
          width: vw(75, $current);
          height: vw(75, $current);
        }
      }
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .left-section-wrapper {
    @include hideScrollbar;
    height: 100vh;
    overflow-y: scroll;
    width: vw(300, $current);
    padding-bottom: vw(30, $current);

    .header-wrapper {
      margin-top: auto;
      padding: vw(30, $current) 0 vw(15, $current);

      .photo-wrapper {
        width: vw(150, $current);
        height: vw(150, $current);
      }

      .name {
        font-size: vw(20, $current);
        margin-top: vw(10, $current);
      }

      .title {
        font-size: vw(18, $current);
        margin-top: vw(10, $current);

        &.smaller {
          font-size: vw(12, $current);
        }
      }
    }


    .sections-wrapper {
      margin-bottom: auto;
      .line {
        margin-top: vw(10, $current);
      }

      .left-side-section {
        margin-top: vw(10, $current);
        font-size: vw(20, $current);

        > span {
          font-size: vw(16, $current);
        }
      }

      .badges-wrapper {
        margin-top: vw(20, $current);

        .badge {
          width: vw(75, $current);
          height: vw(75, $current);
        }
      }
    }
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .left-section-wrapper {
    width: vw(300, $current);
    padding-bottom: vw(30, $current);

    .header-wrapper {
      padding: vw(30, $current) 0 vw(15, $current);

      .photo-wrapper {
        width: vw(150, $current);
        height: vw(150, $current);
      }

      .name {
        font-size: vw(20, $current);
        margin-top: vw(10, $current);
      }

      .title {
        font-size: vw(18, $current);
        margin-top: vw(10, $current);

        &.smaller {
          font-size: vw(12, $current);
        }
      }
    }


    .sections-wrapper {
      margin-bottom: auto;
      .line {
        margin-top: vw(10, $current);
      }

      .left-side-section {
        margin-top: vw(10, $current);
        font-size: vw(20, $current);

        > span {
          font-size: vw(16, $current);
        }
      }

      .badges-wrapper {
        margin-top: vw(20, $current);

        .badge {
          width: vw(75, $current);
          height: vw(75, $current);
        }
      }
    }
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .left-section-wrapper {
    width: vw(400, $current);
    padding-bottom: unset;

    .header-wrapper {
      padding: vw(30, $current) 0 vw(15, $current);

      .photo-wrapper {
        width: vw(150, $current);
        height: vw(150, $current);
      }

      .name {
        font-size: vw(20, $current);
        margin-top: vw(10, $current);
      }

      .title {
        font-size: vw(18, $current);
        margin-top: vw(10, $current);

        &.smaller {
          font-size: vw(12, $current);
        }
      }
    }


    .sections-wrapper {
      margin-bottom: auto;
      .line {
        margin-top: vw(10, $current);
      }

      .left-side-section {
        margin-top: vw(10, $current);
        font-size: vw(20, $current);

        > span {
          font-size: vw(16, $current);
        }
      }

      .badges-wrapper {
        margin-top: vw(20, $current);

        .badge {
          width: vw(75, $current);
          height: vw(75, $current);
        }
      }
    }
  }
}