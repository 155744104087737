@import 'src/styles/imports';
$current: $mobile;
.right-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;

  .spoken-languages {
    font-weight: bold;
    text-align: center;
    font-size: vw(13, $current);
  }

  .footer {
    color: $black;
    width: 100%;
    background-color: $bg-footer;
    padding-bottom: vw(20, $current);

    .right-side {
      display: flex;
      flex-direction: column;
      margin-top: vw(20, $current);
      margin-inline: auto;
      width: 80%;
      align-items: flex-start;

      .title {
        color: $text-title;
        font-size: vw(20, $current);
        font-weight: 700;
        margin-bottom: vw(5, $current);
        text-transform: uppercase;

        &.thinner {
          font-weight: 300;
          font-size: vw(14, $current);

          &:not(:first-of-type) {
            margin: 0;
            padding: 0;
            margin-top: vw(20, $current);
          }
        }
      }

      ul {
        margin: 0;
        padding-inline-start: vw(20, $current);

        li {
          font-size: vw(12, $current);
        }
      }

      .bars-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-basis: auto;
        align-items: center;
        justify-content: center;
        margin-inline: auto;

        .round-bar {
          margin: 0 vw(10, $current) vw(20, $current);
        }
      }

      .icons-wrapper {
        display: flex;
        align-items: center;

        & > * {
          width: vw(24, $current);
          height: vw(24, $current);
          margin-inline-end: vw(5, $current);

          &:last-of-type {
            margin-inline-end: unset;
          }

          &[title="html"] {
            fill: $html-red;
          }

          &[title="css"] {
            fill: $css-blue;
          }

          &[title="node"] {
            fill: $node-green;
          }

          &[title="react"] {
            fill: $css-blue;
          }

          &[title="php"] {
            fill: $php-purple;
          }

        }
      }
    }
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .right-section-wrapper {
    height: 100vh;
    flex: 1;
    .spoken-languages {
      font-size: vw(13, $current);
      text-align: start;
    }

    .footer {
      padding-bottom: vw(20, $current);
      .right-side {
        margin-top: vw(20, $current);
        .title {
          font-size: vw(20, $current);
          margin-bottom: vw(5, $current);

          &.thinner {
            font-size: vw(14, $current);

            &:not(:first-of-type) {
              margin-top: vw(20, $current);
            }
          }
        }

        ul {
          padding-inline-start: vw(20, $current);

          li {
            font-size: vw(12, $current);
          }
        }

        .bars-wrapper {
          .round-bar {
            margin: 0 vw(5, $current) vw(10, $current);
          }
        }

        .icons-wrapper {
          & > * {
            width: vw(24, $current);
            height: vw(24, $current);
            margin-inline-end: vw(5, $current);
          }
        }
      }
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .right-section-wrapper {
    .spoken-languages {
      font-size: vw(13, $current);
    }

    .footer {
      padding-bottom: vw(20, $current);
      .right-side {
        margin-top: vw(20, $current);
        .title {
          font-size: vw(20, $current);
          margin-bottom: vw(5, $current);

          &.thinner {
            font-size: vw(14, $current);

            &:not(:first-of-type) {
              margin-top: vw(20, $current);
            }
          }
        }

        ul {
          padding-inline-start: vw(20, $current);
          li {
            font-size: vw(12, $current);
          }
        }

        .bars-wrapper {
          margin-inline: unset;
          .round-bar {
            margin: 0 vw(5, $current) vw(10, $current);
          }
        }

        .icons-wrapper {
          & > * {
            width: vw(24, $current);
            height: vw(24, $current);
            margin-inline-end: vw(5, $current);
          }
        }
      }
    }
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .right-section-wrapper {
    .spoken-languages {
      font-size: vw(13, $current);
    }

    .footer {
      padding-bottom: vw(20, $current);
      .right-side {
        margin-top: vw(20, $current);
        .title {
          font-size: vw(20, $current);
          margin-bottom: vw(5, $current);

          &.thinner {
            font-size: vw(14, $current);

            &:not(:first-of-type) {
              margin-top: vw(20, $current);
            }
          }
        }

        ul {
          padding-inline-start: vw(20, $current);
          li {
            font-size: vw(12, $current);
          }
        }

        .bars-wrapper {
          .round-bar {
            margin: 0 vw(5, $current) vw(10, $current);
          }
        }

        .icons-wrapper {
          & > * {
            width: vw(24, $current);
            height: vw(24, $current);
            margin-inline-end: vw(5, $current);
          }
        }
      }
    }
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .right-section-wrapper {
    height: 100%;
    .spoken-languages {
      font-size: vw(13, $current);
    }

    .footer {
      padding-bottom: vw(20, $current);
      .right-side {
        margin-top: vw(20, $current);
        .title {
          font-size: vw(20, $current);
          margin-bottom: vw(5, $current);

          &.thinner {
            font-size: vw(14, $current);

            &:not(:first-of-type) {
              margin-top: vw(20, $current);
            }
          }
        }

        ul {
          padding-inline-start: vw(20, $current);
          li {
            font-size: vw(12, $current);
          }
        }

        .bars-wrapper {
          .round-bar {
            margin: 0 vw(5, $current) vw(10, $current);
          }
        }

        .icons-wrapper {
          & > * {
            width: vw(24, $current);
            height: vw(24, $current);
            margin-inline-end: vw(5, $current);
          }
        }
      }
    }
  }
}