@import 'src/styles/imports';
$current: $mobile;
.progress-bar-wrapper {
  margin-top: vw(5, $current);
  height: vw(8, $current);
  background-color: $primary;
  border-radius: vw(5, $current);
  overflow: hidden;

  .actual-progress {
    height: 100%;
    background-color: $link;
  }
}


@media (min-width: $media-tablet) {
  $current: $tablet;
  .progress-bar-wrapper {
    margin-top: vw(5, $current);
    height: vw(8, $current);
    border-radius: vw(5, $current);
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .progress-bar-wrapper {
    margin-top: vw(5, $current);
    height: vw(8, $current);
    border-radius: vw(5, $current);
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .progress-bar-wrapper {
    margin-top: vw(5, $current);
    height: vw(8, $current);
    border-radius: vw(5, $current);
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .progress-bar-wrapper {
    margin-top: vw(5, $current);
    height: vw(8, $current);
    border-radius: vw(5, $current);
  }
}