@import 'src/styles/imports';
$current: $mobile;

.round-progress-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .round-progress-bar {
    position: relative;
    width: 120px;
    height: 120px;
    transform: rotate(270deg);

    svg {
      position: relative;
      width: 100%;
      height: 100%;

      circle {
        width: 100%;
        height: 100%;
        fill: transparent;
        stroke-width: 5;
        stroke: $secondary;

        &.progress {
          stroke: $link;
          stroke-dasharray: 339;
          transition: 0.3s;

          @for $i from 0 through 100 {
            &.value-#{$i} {
              stroke-dashoffset: 339 - ((339 * $i) / 100);
            }
          }
        }
      }
    }

    .number {
      transform: rotate(-270deg);
      text-align: center;
      background-color: transparent;
      position: absolute;
      inset: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h2 {
        margin: 0;
        padding: 0;
        font-size: vw(18, $current);
        line-height: vw(18, $current);

        .smaller {
          vertical-align: super;
          font-size: vw(10, $current);
          font-weight: lighter;
        }
      }

      span {
        font-size: 12px;
        margin: 0;
        padding: 0;
        font-weight: lighter;
      }
    }
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .round-progress-bar-wrapper {
    .round-progress-bar {
      width: 120px;
      height: 120px;

      svg {

        circle {
          stroke-width: vw(5, $current);
        }
      }

      .number {
        h2 {
          font-size: vw(18, $current);
          line-height: vw(18, $current);

          .smaller {
            font-size: vw(10, $current);
          }
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .round-progress-bar-wrapper {
    .round-progress-bar {
      width: 120px;
      height: 120px;

      svg {

        circle {
          stroke-width: vw(5, $current);
        }
      }

      .number {
        h2 {
          font-size: vw(18, $current);
          line-height: vw(18, $current);

          .smaller {
            font-size: vw(10, $current);
          }
        }
      }
    }
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .round-progress-bar-wrapper {
    .round-progress-bar {
      width: 120px;
      height: 120px;

      svg {
        circle {
          stroke-width: vw(5, $current);
        }
      }

      .number {
        h2 {
          font-size: vw(18, $current);
          line-height: vw(18, $current);

          .smaller {
            font-size: vw(10, $current);
          }
        }
      }
    }
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .round-progress-bar-wrapper {
    .round-progress-bar {
      width: 120px;
      height: 120px;

      svg {
        circle {
          stroke-width: vw(5, $current);
        }
      }

      .number {
        h2 {
          font-size: vw(18, $current);
          line-height: vw(18, $current);

          .smaller {
            font-size: vw(10, $current);
          }
        }
      }
    }
  }
}