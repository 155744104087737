@import 'src/styles/imports';
$current: $mobile;
.main-section-wrapper {
  margin-top: vw(30, $current);
  margin-inline: auto;
  width: 80%;
  color: $secondary;

  .title {
    color: $text-title;
    font-size: vw(24, $current);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: vw(-12, $current);
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .main-section-wrapper {
    margin-top: vw(30, $current);

    .title {
      font-size: vw(24, $current);
      margin-bottom: vw(-12, $current);
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .main-section-wrapper {
    margin-top: vw(30, $current);

    .title {
      font-size: vw(24, $current);
      margin-bottom: vw(-12, $current);
    }
  }
}

@media (min-width: $media-desktop-large) {
  $current: $desktop-large;
  .main-section-wrapper {
    margin-top: vw(30, $current);

    .title {
      font-size: vw(24, $current);
      margin-bottom: vw(-12, $current);
    }
  }
}

@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .main-section-wrapper {
    margin-top: vw(30, $current);

    .title {
      font-size: vw(24, $current);
      margin-bottom: vw(-12, $current);
    }
  }
}